import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';  // Import useHistory for React Router v5
import './LetUsKnowForm.css';  // Import the CSS file
import formImage from '../../assets/form.jpeg';

const LetUsKnowForm = () => {
  const history = useHistory();  // Initialize history object for navigation
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!/^[A-Za-z]+$/.test(formData.firstName)) {
      errors.firstName = "Please enter letters only.";
    }
    if (!/^[A-Za-z]+$/.test(formData.lastName)) {
      errors.lastName = "Please enter letters only.";
    }
    if (!/^\d*$/.test(formData.phone) && formData.phone) {
      errors.phone = "Please enter numbers only.";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await fetch('https://www.swivospace.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
          formType: 'owners',
        }), // Convert the form data to JSON
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({ firstName: '', lastName: '', email: '', phone: '', message: '' }); // Reset form
        // Navigate to the thank-you page
        history.push('/list-your-space/thank-you');  // Redirect to the Thank You page
      } else {
        alert('Form submission failed.');
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };

  return (
    <div className="form-container">
      <div className="image-section">
        <img
          src={formImage}
          alt="Contact Us"
          className="contact-image"
        />
        <div className="image-text">
          <h1 className="image-title">Contact Us</h1>
          <p className="image-subtitle">If you have questions about anything on our site, please use this form to contact us.</p>
        </div>
      </div>

      <div className="form-section">
        <h2 className="form-heading">We'd love to hear from you!</h2>
        <form onSubmit={handleSubmit} id="contact-form" className="form">
          <div className="form-row">
            <div>
              <label htmlFor="first-name" className="label">First Name *</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="input"
              />
              {formErrors.firstName && <div className="error-message">{formErrors.firstName}</div>}
            </div>
            <div>
              <label htmlFor="last-name" className="label">Last Name *</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="input"
              />
              {formErrors.lastName && <div className="error-message">{formErrors.lastName}</div>}
            </div>
          </div>

          <label htmlFor="email" className="label">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="input"
          />

          <label htmlFor="phone" className="label">Phone Number (Optional)</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="input"
          />
          {formErrors.phone && <div className="error-message">{formErrors.phone}</div>}

          <label htmlFor="message" className="label">Your message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="textarea"
          ></textarea>

          <input
            type="submit"
            value="Submit"
            className="submit-button"
          />
        </form>
      </div>
    </div>
  );
};

export default LetUsKnowForm;
