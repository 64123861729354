import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';
import PreviewResolverPage from './containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'
  )
);
const BlogPage = loadable(() =>
  import(
    /* webpackChunkName: "BlogPage" */ './containers/BlogPage/BlogPage'
  )
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage')
);
const CMSPage = loadable(() =>
  import(/* webpackChunkName: "CMSPage" */ './containers/CMSPage/CMSPage')
);
const CommunityPage = loadable(() =>
  import(/* webpackChunkName: "CommunityPage" */ './containers/CommunityPage/CommunityPage')
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'
  )
);

const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage')
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const HelpCenterPage = loadable(() =>
  import(/* webpackChunkName: "HelpCenterPage" */ './containers/HelpCenterPage/HelpCenterPage')
);
const InboxPage = loadable(() =>
  import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage')
);
const LandingPage = loadable(() =>
  import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage')
);

const ThankYouPage = loadable(() =>
  import(/* webpackChunkName: "ThankYouPage" */ './containers/ThankYouPage/ThankYouPage')
);

const ThanksPage = loadable(() =>
  import(/* webpackChunkName: "ThanksPage" */ './containers/ThankYouPage/thanks')
);


const ListingPage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'
  )
);
const LocationsPage = loadable(() =>
  import(/* webpackChunkName: "LocationsPage" */ './containers/LocationsPage/LocationsPage')
);
const PartnershipPage = loadable(() =>
  import(/* webpackChunkName: "PartnershipPage" */ './containers/PartnershipPage/PartnershipPage')
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'
  )
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage')
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);
const SearchPage = loadable(() =>
  import(
    /* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */ './containers/SearchPage/SearchPage'
  )
);
const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TrustAndSafetyPage = loadable(() =>
  import(
    /* webpackChunkName: "TrustAndSafetyPage" */ './containers/TrustAndSafetyPage/TrustAndSafetyPage'
  )
);
const WelcomeToSwivoPage = loadable(() =>
  import(
    /* webpackChunkName: "WelcomeToSwivoPage" */ './containers/WelcomeToSwivoPage/WelcomeToSwivoPage'
  )
);

const GetToKnowUsPage = loadable(()=> 
import(
/* webpackChunkName: "GetToKnowUsPage" */ './containers/GetToKnowUsPage/GetToKnowUsPage'
)
);

const TransactionPage = loadable(() =>
  import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage')
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'
  )
);

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() =>
  import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage')
);

const MyWishListPage = loadable(() =>
  import(/* webpackChunkName: "MyWishListPage" */ './containers/MyWishListPage/MyWishListPage')
);

const RequestCancellationPage = loadable(() =>
  import(
    /* webpackChunkName: "MyWishListPage" */ './containers/RequestCancellationPage/RequestCancellationPage'
  )
);

const ProductComparePage = loadable(() =>
  import(
    /* webpackChunkName: "ProductComparePage" */ './containers/ProductComparePage/ProductComparePage'
  )
);

const ContactUsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactUsPage" */ './containers/ContactUsPage/ContactUsPage'
  )
);

const SitemapPage = loadable(()=>
    import(
      /* webpackChunkName: "SitemapPage" */ './containers/SitemapPage/SitemapPage'
    )
)

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
      pageName: 'Swivospace Homepage'
    },

    {
      path: '/list-your-space/thank-you',
      name: 'ThankYouPage',
      component: ThankYouPage,
      pageName: 'Thank You',
    },

    {
      path: '/book-your-space/thank-you',
      name: 'ThanksPage',
      component: ThanksPage,
      pageName: 'Thank You',
    },


    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/spaces',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      pageName: 'Spaces'
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      pageName: 'Listing Details page'
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
      pageName: 'Checkout'
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      pageName: 'Login'
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
      pageName: 'Add your Space'
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
      pageName: 'Edit Listing'
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/blog/:pageId',
      name: 'blogPage',
      component: BlogPage,
      loadData: pageDataLoadingAPI.BlogPage.loadData,
      pageName: 'blog',
    },
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
      pageName: 'Profile'
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
      pageName: 'Profile'
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
      pageName: 'Profile Settings'
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
      pageName: 'Login'
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
      pageName: 'Sign Up'
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
      pageName: 'Confirm'
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
      pageName: 'Recover Password'
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
      pageName: 'Inbox'
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
      pageName: 'Inbox :tab'
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
      pageName: 'Order'
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
      pageName: 'Manage Listing'
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
      pageName: 'Order Details'
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
      pageName: 'Sale'
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
        pageName: 'Sale details'
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
      pageName: 'Account'
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
      pageName: 'Contact Details'
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
      pageName: 'Change Password'
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
      pageName: 'Stripe Payout'
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
      pageName: 'Stripe Payout on Boarding'
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
      pageName: 'Payment Methods'
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
      pageName: 'Terms of Use'
    },
    {
      path: '/trust-and-safety',
      name: 'TrustAndSafetyPage',
      component: TrustAndSafetyPage,
      loadData: pageDataLoadingAPI.TrustAndSafetyPage.loadData,
      pageName: 'Trust and Safety'
    },
    {
      path: '/partnership',
      name: 'PartnershipPage',
      component: PartnershipPage,
      loadData: pageDataLoadingAPI.PartnershipPage.loadData,
      pageName: 'Swivospace Partnerships'
    },
    {
      path: '/locations',
      name: 'LocationsPage',
      component: LocationsPage,
      loadData: pageDataLoadingAPI.LocationsPage.loadData,
      pageName: 'Swivospace Locations'
    },
    {
      path: '/community',
      name: 'CommunityPage',
      component: CommunityPage,
      loadData: pageDataLoadingAPI.CommunityPage.loadData,
      pageName: 'Swivospace Community'
    },
    {
      path: '/help-center',
      name: 'HelpCenterPage',
      component: HelpCenterPage,
      loadData: pageDataLoadingAPI.HelpCenterPage.loadData,
      pageName: 'Swivospace Help Center'
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
      pageName: 'Privacy Policy'
    },
    {
      path: '/welcome-to-swivospace',
      name: 'WelcomeToSwivoPage',
      component: WelcomeToSwivoPage,
      loadData: pageDataLoadingAPI.WelcomeToSwivoPage.loadData,
      pageName: 'About Us'
    },
    {
      path: '/get-to-know-us',
      name: 'GetToKnowUsPage',
      component: GetToKnowUsPage,
      loadData: pageDataLoadingAPI.GetToKnowUsPage.loadData,
      pageName: 'Get to know us'
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
      pageName: 'Reset Password'
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
      pageName: 'Verify Email'
    },
    {
      path: '/favorites',
      name: 'MyWishListPage',
      auth: true,
      authPage: 'LoginPage',
      component: MyWishListPage,
      loadData: pageDataLoadingAPI.MyWishListPage.loadData,
      pageName: 'Favorites'
    },
    {
      path: '/request-cancellation',
      name: 'RequestCancellationPage',
      auth: true,
      authPage: 'LoginPage',
      component: RequestCancellationPage,
      loadData: pageDataLoadingAPI.RequestCancellationPage.loadData,
      pageName: 'Request Cancellation'
    },
    {
      path: '/contact-us',
      name: 'ContactUsPage',
      component: ContactUsPage,
      pageName: 'Contact Us'
    },
    {
      path: '/sitemap',
      name: 'SitemapPage',
      component: SitemapPage,
      loadData: pageDataLoadingAPI.SitemapPage.loadData,
      pageName: 'Sitemap'
    },
    {
      path: '/product-compare',
      name: 'ProductComparePage',
      auth: true,
      authPage: 'LoginPage',
      component: ProductComparePage,
      loadData: pageDataLoadingAPI.ProductComparePage.loadData,
      pageName: 'Product Compare'
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage,
    },
  ];
};

export default routeConfiguration;
